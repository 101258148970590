define("daily-gto/components/my-countdown", ["exports", "ember-countdown/components/countdown-string"], function (_exports, _countdownString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _countdownString.default.extend({
    didReceiveAttrs() {
      this._super(...arguments);

      let today = new Date();
      let tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      let dd = String(tomorrow.getDate()).padStart(2, '0');
      let mm = String(tomorrow.getMonth() + 1).padStart(2, '0'); //January is 0!

      let yyyy = tomorrow.getFullYear();
      tomorrow = mm + '/' + dd + '/' + yyyy + " 00:00:00 GMT";
      this.set("startDate", tomorrow);
    }

  });

  _exports.default = _default;
});