define("daily-gto/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isShowingModalu: false,
    isShowingModal: false,
    actions: {
      toggleModal: function () {
        this.toggleProperty('isShowingModal');
      },
      toggleModalu: function () {
        this.toggleProperty('isShowingModalu');
      }
    }
  });

  _exports.default = _default;
});