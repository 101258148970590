define("daily-gto/components/paper-dialog-inner", ["exports", "ember-paper/components/paper-dialog-inner"], function (_exports, _paperDialogInner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _paperDialogInner.default;
    }
  });
});