define("daily-gto/components/paper-select-menu", ["exports", "ember-paper/components/paper-select-menu"], function (_exports, _paperSelectMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _paperSelectMenu.default;
    }
  });
});