define("daily-gto/models/gto", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    heroHand1: _emberData.default.attr('string'),
    heroHand2: _emberData.default.attr('string'),
    vilainRange: _emberData.default.attr('string'),
    vilainRange_percent: _emberData.default.attr('string'),
    heroRange: _emberData.default.attr('string'),
    heroRange_percent: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    position: _emberData.default.attr('string'),
    blind: _emberData.default.attr('string'),
    pot: _emberData.default.attr('string'),
    heroStack: _emberData.default.attr('string'),
    vilainStack: _emberData.default.attr('string'),
    vilainBetSize: _emberData.default.attr('string'),
    flop1: _emberData.default.attr('string'),
    flop2: _emberData.default.attr('string'),
    flop3: _emberData.default.attr('string'),
    checkCall: _emberData.default.attr('string'),
    checkFold: _emberData.default.attr('string'),
    checkRaise: _emberData.default.attr('string'),
    bet: _emberData.default.attr('string'),
    gtoUrl: _emberData.default.attr('string'),
    today: _emberData.default.attr('string', {
      defaultValue() {
        return new Date();
      }

    })
  });

  _exports.default = _default;
});